html {
    overflow-x: hidden;
}

body {
    font-family: "Open Sans", sans-serif;
    position: relative;
    background: #fff;
}

p {
    line-height: 1.6;
    letter-spacing: 1px;
    font-weight: 300;
    color: #222;
    font-size: 15px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: #111;
}

a:hover,
a:focus {
    text-decoration: none;
    color: #253d53;
    outline: none;
}

a.btn-primary {
    line-height: 30px;
}

ul {
    list-style-type: none;
}

.hide {
    display: none;
}

.bg-greay {
    background-color: #fafafa;
    padding: 100px;
}

.bg-white {
    background-color: #fff;
}

.auth-box .bg-white{    
    padding: 70px;
}

.title h1 {
    text-align: left;
    font: normal normal bold 28px/34px Open Sans;
    letter-spacing: 0px;
    color: #4a3947;
    opacity: 1;
    position: relative;
}

.title h1::after {
    position: absolute;
    left: 0;
    bottom: -10px;
    width: 35px;
    height: 5px;
    background: linear-gradient(90deg, #0068df 0%, #00abe5 100%);
    content: "";
}

label {
    text-align: left;
    font: normal normal normal 16px/21px Open Sans;
    letter-spacing: 0px;
    color: #253d53;
    opacity: 1;
    margin-top: 15px;
}

.form-control {
    background: #f1f1f1 0% 0% no-repeat padding-box;
    border: 1px solid #70707026;
    border-radius: 5px;
    opacity: 1;
    height: 60px;
}

.form-control:focus {
    box-shadow: none;
}

input {
    text-align: left;
    font: normal normal normal 18px/21px Open Sans;
    letter-spacing: 0px;
    color: #a8a8a8;
    opacity: 1;
}

button:focus {
    outline: 0;
}

.btn:focus,
.btn.focus {
    outline: 0;
    box-shadow: none;
}

.btn-primary {
    background: linear-gradient(90deg, #0068df 0%, #00abe5 100%);
    border-radius: 5px;
    width: 172px;
    height: 50px;
}

.btn-without-bg {
    background: #fff !important;
    color: #4a3947;
    border-color: #bdbfc1;
    transform: 1s;
}

.btn-without-bg:hover {
    color: #0072e0;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
    box-shadow: none;
}

.logout button {
    border: 0;
    background: #fff;
    position: absolute;
    top: 15px;
    right: 15px;
}

span.spinner-border.spinner-border-sm {
    margin-right: 10px;
    margin-bottom: 2px;
}

.logout button {
    border: 0;
    background: #fff;
    position: absolute;
    top: 15px;
    right: 15px;
}
/* Start auth */

.auth-title {
    text-align: left;
    font: normal normal bold 20px/27px Open Sans;
    letter-spacing: 0px;
    color: #4a3947;
    opacity: 1;
}

.auth-list li {
    text-align: left;
    font: normal normal normal 17px/23px Open Sans;
    letter-spacing: 0px;
    color: #676767;
    opacity: 1;
    list-style: disc;
    line-height: 30px;
}

.auth-bottom {
    text-align: left;
    font: normal normal normal 16px/19px Open Sans;
    letter-spacing: 0px;
    color: #767881;
}

.auth-bottom a {
    text-align: left;
    text-decoration: underline;
    font: normal normal normal 16px/19px Open Sans;
    letter-spacing: 0px;
    color: #0065df;
}

.auth-box .bg-image {
    background-image: url("../images/auth/bg.png");
    background-position: -6% 136%;
    background-repeat: no-repeat;
}
/* .auth-box input[type="password"] {
        background-image: url("../images/auth/eye.png");
        background-position: 95% center;
        background-repeat: no-repeat;
        background-size: auto;
    } */

.toggle-password {
    position: relative;
    display: inline-block;
    cursor: pointer;
    left: 88%;
    top: -42px;
    z-index: 2;
}

.login-box .bg-image,
.forget-pwd-box .bg-image,
.pwd-success-box .bg-image,
.change-pwd-box .bg-image {
    background-position: -6% 142%;
}
/* Start Registration Page */

.auth-box > .row {
    height: 100vh;
    padding: 15px 0;
}

.register-box-inside,
.login-box-inside,
.forget-pwd-box-inside,
.pwd-success-box-inside,
.change-pwd-inside,
.businessinfo-box-inside {
    border: 1px solid #f3f3f3;
}
/* End Registration Page */
/* Start Forgot Password Page */

.forget-pwd-box-inside h4 {
    font: normal normal normal 18px/21px Open Sans;
    letter-spacing: 0px;
    color: #253d53;
    margin-bottom: 16px;
}

.forget-pwd-box-inside h4 ~ p {
    font: normal normal normal 14px/24px Open Sans;
    letter-spacing: 0px;
    color: #253d53;
}
/* End  Forgot Password Page */
/* End auth */
/* Start User */
/* Start Business Info */

aw-wizard-navigation-bar.horizontal.small ul.steps-indicator li.current .step-indicator {
    background-color: #0065df;
    color: #fff;
}

aw-wizard-navigation-bar.horizontal.small ul.steps-indicator li .step-indicator,
aw-wizard-navigation-bar.horizontal.small ul.steps-indicator li.navigable a:hover .step-indicator {
    left: calc(50% - 12px);
    width: 20px;
    height: 20px;
    top: -16px;
    line-height: 18px;
}

aw-wizard-navigation-bar.horizontal.small ul.steps-indicator li:not(:last-child):after {
    background-color: #0065df;
}

aw-wizard-navigation-bar.horizontal.small ul.steps-indicator li.done .step-indicator {
    color: #fff;
    background-color: #0065df;
    background-image: url("../images/user/tick.png");
    font-size: 0;
    background-position: 50%;
}

aw-wizard-navigation-bar ul.steps-indicator li a .label {
    text-transform: capitalize;
}

.business-type .button input[type="radio"] {
    display: none;
}

.business-type input[type="radio"]:checked + label {
    border: 1px solid #007be0;
    background: #39cdff 0% 0% no-repeat padding-box;
    color: #fff;
}

.business-type .button {
    text-align: center;
}

.business-type .button label {
    font: normal normal normal 13px/15px Open Sans;
    text-align: center;
    width: 93px;
    height: 94px;
    border: 1px solid #253d5341;
    border-radius: 5px;
    padding-top: 16px;
}

.businessinfo-box-inside .bg-greay {
    padding: 65px;
}

button.upload-btn.btn {
    width: 100%;
    border: 2px dashed #253d5380;
    border-radius: 5px;
    font: normal normal normal 16px/19px Open Sans;
    color: #253d53;
    height: 80px;
}
/* End Business Info */
/* Start My Account */

.myaccount-box-inside h4 {
    font: normal normal normal 14px/16px Open Sans;
    color: #253d53;
}

.myaccount-inside {
    font: normal normal normal 14px/16px Open Sans;
    color: #253d53;
}

.myaccount-inside label {
    font: normal normal normal 14px/16px Open Sans;
    color: #7070708c;
}

.btn.logout {
    font: normal normal normal 14px/16px Open Sans;
    color: #253d53;
    padding: 0;
    margin-top: 30px;
}

.logo {
    text-align: center;
}

.center-logo {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.myaccount-box-inside .logo img {
    position: absolute;
    top: 40%;
    left: 35%;
}

.badge {
    width: 68px;
    height: 30px;
    line-height: 25px;
    border-radius: 17px;
}

.badge.pending-badge {
    color: #ff0f21;
    background-color: #ffd0d04d;
    border: 0;
}

.badge.approved-badge,
.badge.verified-badge {
    color: #4caf50;
    background-color: rgb(76 175 80 / 0.3);
    border: 0;
}

.badge.re-apply-badge,
.badge.resend-badge {
    color: #3f51b5;
    background-color: rgb(63 81 181 / 0.3);
    border: 0;
}

.badge.verifying-badge {
    color: #ff8539;
    background-color: rgb(255 133 57 / 0.3);
    border: 0;
}

.submit-app {
    width: 120px;
    height: 30px;
    line-height: 25px;
    border-radius: 17px;
    font-style: italic;
    text-align: right;
    border: 0;
    background: #fff;
    color: #007bff;
    font-weight: 500;
}
/* End My account */
/* End User */
/* Start admin panel */

.leftbar {
    width: 250px;
    height: 100%;
    position: fixed;
    background: #ffffff;
    border-right: 1px solid rgba(0, 0, 0, 0.05);
    z-index: 9;
    transition: all 0.3s ease;
}

.logobar {
    padding: 15px 0;
    margin-bottom: 30px;
    text-align: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.logobar .logo {
    display: inline-block;
}

.logobar .logo img {
    width: 148px;
}

.navigationbar {
    height: calc(100vh - 100px);
    overflow: auto;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.vertical-menu {
    list-style: none;
    margin: 0;
    padding: 0;
}

.vertical-menu li:first-child.vertical-header {
    padding: 5px 25px 5px 25px;
}

.vertical-menu li.vertical-header {
    padding: 15px 25px 5px 25px;
    color: #4e5d71;
    text-transform: uppercase;
    font-size: 12px;
    border-bottom: none;
}

.vertical-menu > li {
    position: relative;
    margin: 0;
    padding: 0;
}

.vertical-menu > li.active {
    background-color: transparent;
}

.vertical-menu > li.active > a {
    font-weight: 400;
    color: #0442ba;
    opacity: 1;
}

.vertical-menu > li > a {
    padding: 8px 15px;
    display: block;
    color: #151d2e;
    margin: 0px 15px;
    border-radius: 3px;
    font-size: 15px;
    line-height: 26px;
    display: inline-flex;
}

.vertical-menu a {
    color: #4e5d71;
    text-decoration: none;
}

.vertical-menu > li > a > i {
    display: inline-block;
    width: 30px;
    font-size: 18px;
}

.rightbar {
    margin-left: 250px;
    /* overflow: hidden;
        min-height: 500px; */
    transition: all 0.3s ease;
}

.topbar {
    background-color: #ffffff;
    padding: 15px 30px;
    position: fixed;
    z-index: 1;
    left: 250px;
    right: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.topbar .infobar {
    display: inline-block;
    float: right;
}

.topbar .infobar > ul > li:last-child {
    margin-right: 0;
}

.profilebar a {
    color: #151d2e;
    padding: 7px 8px 7px;
    display: inline-block;
    border-radius: 3px;
}

.profilebar img {
    width: 26px;
    margin-right: 5px;
}

.profilebar .live-icon {
    font-size: 14px;
}

.profilebar .dropdown-menu {
    top: 14px !important;
    border-radius: 3px;
    padding: 0;
    margin-top: 42px;
}

.profilebar a:hover {
    background-color: rgba(172, 172, 180, 0.1);
}

.dropdown-menu {
    padding: 5px 0;
    font-size: 15px;
    border: none;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
}

.profilebar .dropdown-menu .dropdown-item {
    padding: 8px 15px !important;
    border: none;
    color: #151d2e;
    border-radius: 0;
}

.profilebar a {
    color: #151d2e;
    padding: 7px 8px 7px;
    display: inline-block;
    border-radius: 3px;
}

.dropdown-menu .dropdown-item {
    padding: 0.5rem 1rem;
    color: #4e5d71;
}

.profilebar .dropdown-menu .dropdown-item i {
    font-size: 18px;
    margin-right: 10px;
    vertical-align: middle;
}

.topbar-mobile {
    display: none;
    background-color: #ffffff;
    padding: 15px 25px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.topbar-mobile .mobile-logobar {
    display: inline-block;
}

.topbar-mobile .mobile-togglebar {
    display: inline-block;
    float: right;
    margin-top: 0;
}

.topbar-mobile .mobile-togglebar .menu-hamburger span.iconbar {
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 20px;
    border-radius: 3px;
    color: #151d2e;
}

a.mobile-logo img {
    width: 148px;
}

.toggle-menu .leftbar {
    position: fixed;
    width: 250px;
    margin-left: 0;
    transition: all 0.3s ease;
}

a.menu-hamburger {
    position: relative;
    top: 5px;
}
/* End admin panel */

.public-data h1 {
    font-size: 30px !important;
    color: #141414;
    margin-bottom: 60px;
    font-size: 19px;
    font-weight: normal;
    line-height: 23px !important;
    margin-bottom: 5px;
}

.public-data p {
    font-size: 14px;
    line-height: 1.6;
}

.public-data ul {
    list-style: disc;
    padding-top: 5px;
}

.public-data ul li {
    font-size: 14px;
    line-height: 1.6;
}

.public-data h2 {
    padding-top: 50px;
    font-size: 18px;
    color: #141414;
    line-height: 1.6;
    padding-top: 50px;
    padding-bottom: 15px;
    font-weight: bold;
}

.public-data h3 {
    color: #141414;
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 10px;
    font-weight: bold;
}

.public-data h4 {
    font-size: 13px;
    font-weight: bold;
    padding-top: 19px;
    margin-bottom: 0;
}

.c-tag-1{
    color: #0073DE;
}

.c-tag-2{
    color: #545454;
}

.c-tag-3{
    color: #11A6E5;
}

.c-tag-4{
    color: #818181;
}

.c-tag-5{
    color: #62D0FF;
}

.c-tag-6{
    color: #ffffff;
}
.pointer{
    cursor: pointer;
}
.bold-26{
	font-size: 1.625rem;
    font-weight: 700;
}

.bold-16{
	font-size: 1rem;
    font-weight: 700;
}
.header-body {
    width: 100%;
    margin-top: 1.7rem;
    background: #fff;
    padding: 1rem 2.5rem;
    border-radius: 0.4375rem;
    min-height: 56rem;
}

.cf-xs{
    padding-right: 0px;
    padding-left: 0px;
}

.header-title{
    border-bottom: 1px solid #D7D7D7;
}

.header-title-2 .search{
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
}

.header-title-2 input{
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    background-color: #EEEEEE;
    height: 40px;
}

.header-title-2 .search svg{
    position: absolute;
    width: 18px;
    height: 15px;
    top: 15px;
    right: 20px;
}
.pac-container { z-index: 100000; }
.mat-standard-chip.mat-chip-with-trailing-icon{
    height: auto !important;
}
.white-spaces{
    white-space: pre-line;
}
.p-dropdown .p-dropdown-trigger {
    background: #f1f1f1 0% 0% no-repeat padding-box !important;
    
}

.p-dropdown .p-dropdown-label.p-placeholder {
    padding: 14px !important;
}
.p-dropdown .p-dropdown-label {
    background: #f1f1f1 0% 0% no-repeat padding-box !important;
    padding: 14px !important;
}

.p-dropdown  {
   border:none !important;
}

.center {
    text-align: center;
}

.d-flex {
    display: flex!important;
}

.justify-content-around {
    justify-content: space-around!important;
}