@media (min-width: 768px) and (max-width: 1024px) {
    .bg-greay,
    .bg-white {
        padding: 50px;
    }
    /* input {
        width: 100%;
    }
    .main {
        position: inherit !important;
        margin-left: 0 !important;
    } */
}

@media (max-width: 768px) {
    .bg-greay,
    .bg-white,
    .businessinfo-box-inside .bg-greay {
        padding: 10px;
    }
    .auth-box .bg-image {
        background-image: url('../images/auth/bg.png');
        background-position: 50% 220%;
    }
    .auth-box input[type="password"] {
        background-position: 98% center;
    }
    .auth-box .mb-5 {
        margin-bottom: 2rem !important;
    }
    .btn-primary {
        width: 140px;
        height: 40px;
        font-size: 15px;
    }
    .myaccount-box-inside .logo img {
        position: relative;
        top: 25%;
    }
    .topbar {
        display: none;
    }
    .topbar-mobile {
        display: block;
        width: 100%;
        z-index: 1;
    }
    .leftbar {
        position: fixed;
        margin-left: -250px;
    }
    .rightbar {
        margin-left: 0 !important;
    }
    input {
        width: 100%;
    }
    .main {
        position: inherit !important;
        margin-left: 0 !important;
    }
    .banner h1 {
        font-size: 25px !important;
        margin-top: 0px !important;
    }
}

@media (max-width: 340px) {
    .btn-primary {
        width: 115px;
        font-size: 12px;
    }
    input {
        width: 100%;
    }
}