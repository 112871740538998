/* You can add global styles to this file, and also import other style files */

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,600;0,700;1,600&display=swap");
@import url("../src/assets/css/style.css");
@import url("../src/assets/css/responsive.css");
@import "~@ng-select/ng-select/themes/material.theme.css";

@import "@yaireo/tagify/dist/tagify.css";

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");

@font-face {
    font-family: "SFPro-Light";
    src: url("assets/fonts/SFPro-Light.ttf");
}

@font-face {
    font-family: "SFPro-Semi";
    src: url("assets/fonts/SFPro-Semi.ttf");
}

@font-face {
    font-family: "SFPro-Semi";
    src: url("assets/fonts/SFPro-Semi.ttf");
}

* {
    box-sizing: border-box;
}
body,
div,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 0;
    font-weight: normal;
}
.fade-btn {
    outline: none;
    border: none;
    background-color: transparent;
}
body,
button {
    font-family: "Inter", sans-serif;
}
body {
    font-size: 14px;
    min-height: 100vh;
    background-color: #e5e5e5;
}
img {
    max-width: 100%;
    vertical-align: middle;
}
.page-width {
    max-width: 1143px;
    margin: auto;
    /* padding: 22px 0 100px 0; */
}
.disabled {
    background-color: #11a6e580 !important;
}
/*
      DEMO STYLE
  */

 /*  tools tab animation start */
 .ng-select .ng-clear-wrapper,.ng-select.ng-select-multiple .ng-select-container.ng-has-value .ng-clear-wrapper {
    display: none !important;
  }
  /* .commonSteps .tab-content .tab-pane.fade.active.show .introText {     
    animation-name: moveInleft;
    animation-duration: 2s;
  }
  .commonSteps .tab-content .tab-pane.fade.active.show {
    transition: opacity 0.011s linear;
} */
  /* .commonSteps .tab-content{
   
    overflow: hidden;
  } */
  /* @keyframes moveInleft { 
    0% {
        opacity: 0;
        transform: translateX(100%);
    }
    
    100% {
        opacity: 1;
        transform: translate(0);
    }
} */
 
 /*  tools tab animation close */


.fade-btn {
    border: none;
    outline: none;
    background-color: transparent;
}
.fade-btn:focus {
    outline: none;
}
p {
    font-size: 1.1em;
    font-weight: 300;
    line-height: 1.7em;
    color: #999;
}

a,
a:hover,
a:focus {
    color: #007bff;
    text-decoration: none;
    background-color: initial;
    transition: all 0.3s;
    cursor: pointer;
}
.app-text-primary {
    color: #0073de;
}
.grid {
    display: grid;
}
.grid-body {
    padding: 0 20px;
    width: calc(100% - 195px);
    margin-bottom: 100px;
}
.grid-body-nologin {
    padding: 0;
    width: 100%;
    margin-bottom: 100px;
    margin: auto;
}
.container-nav {
    margin-bottom: 22px;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07);
    position: fixed;
    width: 100%;
    z-index: 2;
}
.navbar {
    height: 82px;

    padding: 0 20px;
    border: none;
    flex-wrap: nowrap;
    border-radius: 0;
    max-width: 1143px;
    margin: auto;
}
.bold {
    font-weight: bold;
}
.avatar {
    padding: 5px 6px 5px 22px;
    border: 1px solid #11a6e5;
    border-radius: 50px;
    margin: 21px 0;
}
.avatar-text {
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.avatar-text p {
    font-size: 12px;
    line-height: 15px;
    flex-direction: column;
    color: #545454;
}
.w-100-lg-only {
    width: 100%;
    height: 100%;
}
div.admin-logo {
    width: 177px;
    border-bottom: 3px solid #0073de;
    height: 100%;
}
div.admin-logo img {
    width: 100px;
    height: 20px;
}
.navbar-btn {
    box-shadow: none;
    outline: none !important;
    border: none;
}

.line {
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed #ddd;
    margin: 40px 0;
}

/* ---------------------------------------------------
      SIDEBAR STYLE
  ----------------------------------------------------- */

.wrapper {
    display: flex;
    width: 100%;
}
#sidebar {
    height: 77.5%;
    background: #fff;
    border-radius: 7px;
    color: #fff;
    transition: all 0.3s;
    max-height: 100%;
    position: fixed;
    top: 108px;
    /* left: 1.8rem; */
    width: 177px;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

#sidebar.active {
    margin-left: -250px;
}

#sidebar ul li a {
    padding: 16px 17px;
    display: block;
    color: #c1c1c1;
    font-weight: 500;
    border-bottom: 1px solid #ececec;
}

#sidebar ul li a span {
    color: #796868;
    margin-left: 15px;
}
.components .first a {
    border-radius: 7px 7px 0px 0px !important;
}
#sidebar ul li a:hover {
    color: #7386d5;
    background: #fff;
}

#sidebar ul li.active > a,
a[aria-expanded="true"] {
    color: #0073de;
    background: #ceebf8;
}

a[data-toggle="collapse"] {
    position: relative;
}

.dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
}

ul ul a {
    font-size: 13px !important;
    padding-left: 30px !important;
}

/* ---------------------------------------------------
      CONTENT STYLE
  ----------------------------------------------------- */
.myaccount-box {
    background-color: #fff;
    padding: 30px;
    border-radius: 7px;
    min-height: 100vh;
}

#content {
    /* width: calc(100% - 250px);  */
    transition: all 0.3s;
    /* position: absolute; */
    margin-left: 198px;
    margin-top: 110px;
    /* background: #ffffff; */
    border-radius: 7px;
    /* padding: 30px; */
    width: 100%;
    /* margin-top: 1.7rem; */
    /* background: #fff; */
    /* padding: 1rem 2.5rem; */
    /* border-radius: 0.4375rem; */
    /* min-height: 70rem; */
}

#content.active {
    width: 100% !important;
}

/* ---------------------------------------------------
      MEDIAQUERIES
  ----------------------------------------------------- */

.owl-theme .owl-nav [class*="owl-"] {
    background-image: none !important;
    background: #f4f4f4 !important;
}
.carousel-control-prev-icon {
    background-image: none !important;
}
.owl-nav .owl-next {
    right: 0;
    position: absolute;
}
.owl-nav .owl-prev {
    left: 0;
    transform: rotate(180deg);
    position: absolute;
}
.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
    background: #0073de !important;
    border: 1px solid #0073de !important;
}
.owl-theme .owl-dots .owl-dot span {
    background: #ffffff !important;
    border: 1px solid #cecece !important;
}
.owl-nav {
    position: relative;
    top: -216px;
}

.modal-content {
    border-radius: 15px;
}
div#shopPopup .col-lg-10 {
    max-width: 100% !important;
    flex: auto;
}
div#shopPopup .myaccount-box {
    min-height: inherit;
}
button.app-popup-close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    padding: 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    position: relative;
    top: 45px;
    right: 34px;
    z-index: 1;
}
.docUrl{
    align-items: center;
    text-align: center;
    color: #007bff;
    cursor: pointer;
    
}
.ng2-pdf-viewer-container{
    margin-top: -49px!important;
    height: 120%!important;
    overflow-x: unset!important;
}

.loader{
    height: 52%;
    margin: 0;
    position: absolute;
    top: 38%;
    left: 44%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.zero-state-add-btn{
    font-size: 1rem;
    margin-bottom: 0.5rem;
    color: #fff!important;
    cursor: pointer;
    text-transform: uppercase;
    font-family: 'Inter';
    font-style: normal;
    font-weight: bold;
    text-align: right;
    background: #11A6E5;
}

.left-34{
    left: 34% !important;
}

.form-control-select .ng-select-container {
    border: 2px solid #6291D8;
    padding-left: 5px;
    min-height: unset;
    height: 48px;
    width: 100% !important;
    background-color: #fff;
    display: block !important;
}
.form-control-select.ng-select.ng-select-multiple .ng-select-container.ng-has-value .ng-arrow-wrapper{
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}
.form-control-select.ng-select .ng-select-container:after{
    display: none;
}
.form-control-select.ng-select.ng-select-multiple .ng-select-container.ng-has-value .ng-value-container,
.form-control-select.ng-select .ng-select-container .ng-value-container{
    padding-top: 0;
    border-top-width: 7px;
}
.form-control-select.ng-select.ng-select-multiple .ng-select-container.ng-has-value .ng-clear-wrapper, .form-control-select.ng-select.ng-select-multiple .ng-select-container.ng-has-value .ng-arrow-wrapper{
    border-top-width: 0;
}
.form-control-select.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value{
    background-color: #2F79E8;
}

@media (max-width: 1081px) {
    .owl-nav {
        top: -244px;
    }
}
@media (max-width: 1014px) {
    .owl-nav {
        top: -258px;
    }
    .shop .body-card {
        height: 370px !important;
    }
    .account-info {
        margin-top: 0;
    }
}
@media (max-width: 992px) {
    .w-100-lg-only {
        width: auto;
    }
    .page-width,
    .container-nav {
        /* padding: 0 20px; */
    }
    .owl-nav {
        top: -212px;
    }
    div#shopPopup .product {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(3, 1fr);
    }
    div#shopPopup #comming-soon {
        left: -73px;
    }
    div#shopPopup .shop-title {
        line-height: 2;
    }
}
@media (max-width: 768px) {
    div#shopPopup #comming-soon {
        left: -42px !important;
    }
    #sidebar {
        margin-left: -250px;
        z-index: 1;
    }
    #sidebar.active {
        margin-left: 0;
    }
    #content.active {
        width: calc(100% - 250px);
    }
    #sidebarCollapse span {
        display: none;
    }
    .grid-body {
        width: 100%;
    }
    #content {
        width: 100%;
        margin-left: 0;
    }
    button.app-category , button.app-product{
        width: 100% !important;
    }
    .category-name input.form-control,.options-group,.product-name input.form-control,.product-textarea textarea {
        width: 100% !important;
    }
    .item-option td {
        display: block;
        width: 100%;
        clear: both;
    }
    td.close-btn {
        display: table-cell;
        width: 15%;
        clear: both;
    }
    .item-option td:nth-child(even),.item-option td:nth-child(odd) {
        padding: 0 !important;
    }
    .app-store .store-time td.store-time-label {
        width: 145px;
        padding: 0 !important;
        padding-left: 20px !important;
        text-align: left;
    }
    .form-check .form-check-input {
        margin-left: 0.5em !important;
        position: absolute;
        right: 0;
    }
    .app-store .store-time td {
        padding: 4px !important;
    }
    .app-store .btn-primary {
        width: 100% !important;
    }
}
@media (max-width: 601px) {
    div#shopPopup #comming-soon {
        left: -59px !important;
    }
}
@media (max-width: 600px) {
    div#shopPopup #comming-soon {
        left: -20px !important;
    }
}
/* @media (max-width: 575px) {
    div#shopPopup #comming-soon {
        left: -6px !important;
    }
} */
/* @media (max-width: 524px) {
    div#shopPopup #comming-soon {
        left: 0px !important;
    }
} */
/* @media only screen and (max-width: 550px) {
    div#shopPopup #comming-soon {
        margin-left: 50px;
    }
} */

@media (max-width: 575px) {
    div#shopPopup #comming-soon {
        left: -20px !important;
    }
}
@media (max-width: 550px) {
    div#shopPopup #comming-soon {
        left: 0 !important;
    }
}
@media (max-width: 486px) {
    div#shopPopup #comming-soon {
        left: 10px !important;
    }
}
@media (max-width: 452px) {
    div#shopPopup #comming-soon {
        left: 0px !important;
    }
}
@media (max-width: 420px) {
    div#shopPopup #comming-soon {
        margin-left: -45px !important;
    }
}
@media (max-width: 450px) {
    .owl-nav {
        top: -320px;
    }
    .shop .body-card {
        height: 428px !important;
    }
}
@media (max-width: 420px) {
    div#shopPopup #comming-soon {
        margin-left: 62px !important;
    }
}
@media (max-width: 375px) {
    div#shopPopup #comming-soon {
        margin-left: 50px !important;
    }
}
